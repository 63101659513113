<template>
  <main class="dgrid-body">
    <h2 class="view-title">{{ $t('partecipa_titolo_sez_3') }}</h2>
    <p class="text--subtitle view-subtitle"></p>

    <p v-html="parsedText" class="marked"></p>

  </main>
</template>

<script>
import * as marked from 'marked';
export default {
  name: 'Contacts',
  computed: {
    parsedText() {
      return marked.parse(this.$t('partecipa_testo_sez_3'));
    }
  }
}
</script>
